import Head from 'next/head'
import React from 'react'

export default function Header() {
  return (
    <Head>
      <title>ANET Transport Co.</title>
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <meta
        name='keywords'
        content=' ANET Transport, ANET transport ltd, Christmas, Christmas Offers, Christmas Transportation promo, Transport Discount, Easter 2021,Easter Special Offers , Restaurant discount,Travel discount,Travel deals, Easter Travel Deals, Easter Sunday, Holiday Hangout, Spiritual Retreats, Church programs, Easter camp meeting, online bus ticket booking, online bus booking in nigeria, best transport company in Nigeria, More leg room, best trip prices, child fare rates, manage your booking, discount for tickets, discount for children tickets, travel &amp; Tours, pick up service, bus terminals, transport company in Nigeria, best transport company in nigeria, ANET motors , round trip, ANET transport,ANET online, ANET booking, ANET online booking, ANET motors, ANET logistics, ANET express, ANET motors,  ANET app, Commute between Lagos-Onitsha, Abuja-Onitsha, Port Harcourt-Onitsha, Onitsha-Port Harcourt, Onitsha-Owerri, Onitsha-Abuja, Onitsha-Lagos, Lagos-Owerri, Lagos-Umuahia, Lagos-Abuja, Lagos-Calabar, Lagos-Aba, Lagos-Port Harcourt, Lagos-Uyo, Lagos-Accra, Lagos-Cotonou, Lagos-Lome, Lagos-Kaduna, Lagos-Onitsha, Lagos-Awka, Lagos-Enugu, Lagos-Warri'
      />
      {/* HTML Meta Tags */}
      <meta
        name='description'
        content='ANET is a transport company birthed from a passion to run a modern road transportation service in Nigeria. We aim to correct the problems experienced by road commuters.We operate within Nigeria in our various terminals located at Enugu, Lagos, Abuja, Anambra, Imo, and many more and provide comfortable buses for differently-abled individuals. We offer charter services haulage services for companies and individuals.'
      />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp='name' content='ANET Transport Co.' />
      <meta
        itemProp='description'
        content='ANET is a transport company birthed from a passion to run a modern road transportation service in Nigeria. We aim to correct the problems experienced by road commuters. We operate within Nigeria in our various terminals located at Enugu, Lagos, Abuja, Anambra, Imo, and many more and provide comfortable buses for differently-abled individuals. We offer charter services haulage services for companies and individuals.'
      />
      <meta
        itemProp='image'
        content='https://res.cloudinary.com/elouisramsey/image/upload/f_auto,q_auto:best/v1639410834/ecommerceAPI/ANET/f476ma0s1dk8wf3hf1ys.png'
      />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property='og:url' content='https://www.anettransport.com' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content='ANET Transport Co.' />
      <meta
        property='og:description'
        content='ANET is a transport company birthed from a passion to run a modern road transportation service in Nigeria. We aim to correct the problems experienced by road commuters. We operate within Nigeria in our various terminals located at Enugu, Lagos, Abuja, Anambra, Imo, and many more and provide comfortable buses for differently-abled individuals. We offer charter services haulage services for companies and individuals.'
      />
      <meta
        property='og:image'
        content='https://res.cloudinary.com/elouisramsey/image/upload/f_auto,q_auto:best/v1639410834/ecommerceAPI/ANET/f476ma0s1dk8wf3hf1ys.png'
      />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='ANET Transport Co.' />
      <meta
        name='twitter:description'
        content='ANET is a transport company birthed from a passion to run a modern road transportation service in Nigeria. We aim to correct the problems experienced by road commuters. We operate within Nigeria in our various terminals located at Enugu, Lagos, Abuja, Anambra, Imo, and many more and provide comfortable buses for differently-abled individuals. We offer charter services haulage services for companies and individuals.'
      />
      <meta
        name='twitter:image'
        content='https://res.cloudinary.com/elouisramsey/image/upload/f_auto,q_auto:best/v1639410834/ecommerceAPI/ANET/f476ma0s1dk8wf3hf1ys.png'
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Head>
  )
}
