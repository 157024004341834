import React, { createContext, useContext, useEffect, useState } from 'react'
const STORAGE_KEY = 'PassengerBooking'

const PassengerContext = createContext()

export function PassengerProvider({ children }) {
  const [globalState, setGlobalState] = useState({})
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storageState =
        window.localStorage.getItem(STORAGE_KEY) || JSON.stringify({})

      setGlobalState(JSON.parse(storageState))
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(globalState))
    }
  }, [globalState])

  return (
    <PassengerContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </PassengerContext.Provider>
  )
}

export function usePassenger() {
  const context = useContext(PassengerContext)
  if (!context) {
    throw new Error('usePassenger must be used within a PassengerProvider')
  }
  return context
}
